export const environment = {
    banorte: false,
    production: true,
    ente: "H. Ayuntamiento de Escuinapa",
    administracion: "2024 - 2027",
    url_escudo: "../assets/escuinapa/ESCUDO.jpg",
    url_administracion: "../assets/escuinapa/logo_escuinapa_admin_2024_2027.png",
    url_footer: "../assets/escuinapa/logo_escuinapa_admin_2024_2027.png",
    direcciones: [
        "Palacio Municipal S/N",
        "C.P. 82400",
        "Escuinapa, Sinaloa"
    ],
    telefonos: [
        "Sub-Dirección de Ingresos 695 953 34 89",
        "Tesorería 695 953 02 92"
    ],
    emails: [
        "asuntos.escuinapa@escuinapa.gob.mx",
        "pagos.enlinea@escuinapa.gob.mx"
    ],
    url_aviso: "https://www.escuinapa.gob.mx/",
    url_webapi: "https://pagosescuinapa.azurewebsites.net/api/",
    url_sitio: "https://pagosescuinapa.com.mx/",
    url_rpt: 'http://servidorsecomrpt.ddns.net:8081/api/Ingresos/GetReciboPredialEscuinapa',
    cve_municipio: "014",
    municipio: "ESCUINAPA",
    firebase: {
        apiKey: "AIzaSyDvXDegPLYVeiA6QcwBfW1BTEYflNt2Atc",
        authDomain: "pagosescuinapa.firebaseapp.com",
        projectId: "pagosescuinapa",
        storageBucket: "pagosescuinapa.appspot.com",
        messagingSenderId: "80744570574",
        appId: "1:80744570574:web:f970f2537bec34d24dba9f",
        measurementId: "G-G23R1QZDY1"
    },
    leyenda_fundamento: "Ley de ingresos del municipio de Escuinapa para el año 2017. Artículos 1,2,5,7,8,9. Ley de" +
        "Hacienda Municipal del Estado de Sinaloa vigente para el año 2017 Artículos 30 al 37 y 105" +
        "Capítulo II. Ley deCatastro del Estado de Sinaloa Artículos 13,14 23 al 26 y 32 al 34. Ley" +
        "de Gobierno Municipal del Estado de Sinaloa Artículo 59 Fracción III y IV.",
    leyenda_recibo: "EN CASO DE REQUERIR FACTURA ELECTRONICA, SOLICITARLO EN UN PLAZO NO MAYOR A 3 DIAS DESPUES DEL MES EN QUE REALIZO EL PAGO",
    id_express: "2794",
    llave: "-v0aeT5E1AtAelqxsjcG",
    img_ayuda_1: '../assets/escuinapa/ayuda_1.png',
    img_ayuda_2: '../assets/escuinapa/ayuda_2.png',
    img_ayuda_3: '../assets/escuinapa/ayuda_3.png'
  };